import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  margin: auto;
  padding-bottom: 10px;

  @media only screen and (min-width: 1100px) {
    max-width: 65%;
  }

  @media only screen and (min-width: 1441px) {
    max-width: 40%;
  }
`;

export const Copyright = styled.p`
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8em;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Disclaimer = styled.p`
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 0.8em;
  padding-left: 30px;
  padding-right: 30px;
`;

export const VersionInfo = styled.p`
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 0.8em;
  padding-left: 30px;
  padding-right: 30px;
  opacity: 0.5;
`;
