import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    min-width: 320px;
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.background};
    font-family: 'calling-code', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em; 

    margin: 0 0 100px;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0.5em;
      background-color: ${({ theme }) => theme.background};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.scrollbar};
      outline: none;
    }
    
    a {
      color: ${({ theme }) => theme.button.text};
      text-decoration: none;
      text-decoration-color: ${({ theme }) => theme.button.background};
      background-color: ${({ theme }) => theme.button.background};
      :hover {
        color: ${({ theme }) => theme.heading.text};
        background-color: ${({ theme }) => theme.heading.background};
      }
      :active {
        color: ${({ theme }) => theme.heading.text};
        background-color: ${({ theme }) => theme.heading.background};
      }
    }
  }
`;

export const PageWrap = styled.div`
  position: relative;
  min-height: 90vh;
`;

export const Content = styled.div`
  padding-bottom: 4em;
`;

export const PageFooter = styled.div`
  position: absolute;
  bottom: 0;
  height: 4em;
  width: calc(100% - 0.5em);
`;

export default GlobalStyle;
