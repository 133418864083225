import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import sample from 'lodash/fp/sample';
import CharacterHeader from './CharacterHeader';
import CharacterGrid from './CharacterGrid';
import CharacterButton from './CharacterButton';
import { randomCharacter } from './Random';
import { Wrapper } from './styled';
import { themes } from '../Themes';
import AppContext from '../../AppContext';
import { FormattedMessageWithoutAsterisk } from '../Export/Print/Character';

const Character = () => {
  const [state, setState] = useContext(AppContext);
  const { formatMessage } = useIntl();

  const updateCharacter = () => {
    setState({
      ...state,
      character: randomCharacter(state.homebrew),
      theme: sample(themes.filter((t) => t.id !== state.theme.id)),
    });
  };

  const handleClick = () => {
    updateCharacter();
  };

  const getName = () => {
    if (state.character.name?.parts)
      return state.character.name.parts
        .map((id) => formatMessage({ id }))
        .join('')
        .replace('*', '');
    else return state.character.name.replace('*', '');
  };

  const title = (
    <>
      <FormattedMessageWithoutAsterisk
        id={`character.classes.${state.character.class}`}
      />
      {' - '}
      {getName()}
    </>
  );

  return (
    <Wrapper>
      <title>{title}</title>
      <CharacterButton onClick={handleClick} />
      {state.character && <CharacterHeader character={state.character} />}
      {state.character && <CharacterGrid character={state.character} />}
    </Wrapper>
  );
};

export default Character;
