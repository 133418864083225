import random from "lodash/fp/random";

export const rollAbility = (modifier, modifiers) => {
  const abilityScore = threeD6(modifier);
  return modifiers.find((modifier) => modifier.numbers.includes(abilityScore));
};

const threeD6 = (modifier = 0) => {
  const one = random(1, 6);
  const two = random(1, 6);
  const three = random(1, 6);
  return one + two + three + modifier;
};
