import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Button} from './styled';
import AppContext from '../../AppContext';

const Status = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

async function tcp(fn) {
  return new Promise((r) => {
    fn()
      .then((v) => r(v))
      .catch((e) => {
        console.error(e);
        r(e);
      });
  });
}

const transformCharacter = (character, formatMessage) => {
  const abilities = character.abilities;
  const equipment = character.equipment
    .filter(({ tags }) => !tags.includes('silver'))
    .filter(({ tags }) => !tags.includes('scroll'));
  const armor = equipment.find(({ tags }) => tags.includes('armor'));
  const shield = equipment.some(({ tags }) => tags.includes('shield'));
  const weapon = equipment.find(({ tags }) => tags.includes('weapon'));
  const money = character.equipment.find(({ tags }) => tags.includes('silver'))
    .amount;
  const scrolls = character.equipment.filter(({ tags }) =>
    tags.includes('scroll')
  );

  const transformTales = () => {
    const origin = formatMessage({ id: character.origin.id });
    if (character.class === 'dead-gods-prophet') {
      const name = character.origin.name;
      const title = formatMessage({ id: character.origin.title });
      const domain = formatMessage({ id: character.origin.domain });

      return `${origin} ${name}, ${title} ${domain}.`;
    }
    return origin;
  };

  const transformHabits = () => {
    if (character.class === 'dead-gods-prophet') {
      const name = character.origin.name;

      return formatMessage(
        {
          id: 'character.classes.dead-gods-prophet.origin.appendix',
        },
        { name }
      );
    }
    return formatMessage({
      id: `character.classes.${character.class}.origin.appendix`,
    });
  };

  const transformTraits = () => {
    const firstTrait = formatMessage({ id: character.traits[0] });
    const secondTrait = formatMessage({
      id: character.traits[1],
    }).toLowerCase();
    const traits = formatMessage(
      {
        id: 'tables.traits.combined',
      },
      {
        first: firstTrait,
        second: secondTrait,
      }
    );
    const body = formatMessage({ id: character.body });
    const habit = formatMessage({ id: character.habit.id });
    const tale = character.tale && formatMessage({ id: character.tale.id });
    return `${traits} ${body} ${habit}${tale ? ` ${tale}` : ''}`;
  };

  return {
    ability: {
      agility: abilities.agility.mod,
      presence: abilities.presence.mod,
      strength: abilities.strength.mod,
      toughness: abilities.toughness.mod,
    },
    actions: character.powers.map(({id}) =>
        formatMessage({
          id: `character.classes.${character.class}.power.${id}.title`,
        })
    ),
    class: formatMessage({id: `character.classes.${character.class}`}),
    equipment: {
      armor: armor ? formatMessage({id: armor.title}) : '',
      shield: shield,
      weapon: weapon ? formatMessage({id: weapon.title}) : '',
    },
    habits: transformHabits(),
    health: character.hp,
    inventory: equipment.map((item) => ({
      name: formatMessage(
          {
            id: item.title,
          },
          {amount: item.amount, hp: item.hp}
      ),
      quantity: item.amount,
      health: item.hp,
    })),
    money,
    name: character.name?.parts
        ? character.name.parts
            .map((id) => formatMessage({id}))
            .join('')
            .replace('*', '')
        : character.name.replace('*', ''),
    omens: {
      notation: `d${character.omens.die}`,
      total: character.omens.rolled,
    },
    scrolls: scrolls.map((item) => formatMessage({id: item.title})),
    tales: transformTales(),
    traits: transformTraits(),
  };
};

const Modron = ({ authorization }) => {
  const [ctx] = useContext(AppContext);
  const { formatMessage } = useIntl();
  const [modronCharacter, setModronCharacter] = useState(
    transformCharacter(ctx.character, formatMessage)
  );
  const [status, setStatus] = useState(Status.IDLE);

  useEffect(() => {
    setModronCharacter(transformCharacter(ctx.character, formatMessage));
    setStatus(Status.IDLE);
  }, [ctx.character, formatMessage]);

  const onClick = async () => {
    let body, headers, response;

    body = JSON.stringify(modronCharacter);
    headers = { authorization, 'content-type': 'application/json' };

    setStatus(Status.PENDING);

    response = await tcp(() =>
      fetch(
        'https://api.modron.one/mork_borg/api/characters/import_scvmbirther',
        { body, headers, method: 'POST' }
      )
    );
    if (!response?.ok) return setStatus(Status.ERROR);

    setStatus(Status.SUCCESS);
  };

  return (
    authorization && (
      <Button
        disabled={[Status.PENDING, Status.SUCCESS].includes(status)}
        onClick={onClick}
        type='button'
      >
        {status === Status.IDLE && formatMessage({ id: 'app.modron.idle' })}
        {status === Status.PENDING &&
          formatMessage({ id: 'app.modron.pending' })}
        {status === Status.SUCCESS &&
          formatMessage({ id: 'app.modron.success' })}
        {status === Status.ERROR && formatMessage({ id: 'app.modron.error' })}
      </Button>
    )
  );
};

export default Modron;
