import React from "react";
import { AppContextProvider } from "./AppContext";
import Logo from "./features/Header";
import Character from "./features/Character";
import Menu from "./features/Menu";
import GlobalStyle, { PageWrap, PageFooter, Content } from "./GlobalStyle";
import Footer from "./features/Footer";
import "intl-pluralrules";

function App() {
  return (
    <AppContextProvider>
      <GlobalStyle />
      <PageWrap>
        <Content>
          <Logo />
          <Character />
        </Content>

        <PageFooter>
          <Menu />
          <Footer />
        </PageFooter>
      </PageWrap>
    </AppContextProvider>
  );
}

export default App;
