import sample from 'lodash/fp/sample';

export const rollDeadGod = (origin, table) => {
  const name = sample(table.names);
  const title = sample(table.titles);
  const domain = sample(table.domains);

  return {
    id: origin,
    name,
    title,
    domain,
  };
};
