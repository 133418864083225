import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from './styled';
import AppContext from '../../../AppContext';

const ToggleLanguageButton = () => {
  const [state, setState] = useContext(AppContext);

  const toggleLanguage = () => {
    localStorage.setItem('locale', state.locale === 'en' ? 'sv' : 'en');

    setState({
      ...state,
      locale: localStorage.getItem('locale')
    });
  };

  return (
    <Button onClick={toggleLanguage}>
      <FormattedMessage id='app.change-language' />
    </Button>
  );
};

export default ToggleLanguageButton;
