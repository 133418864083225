import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Box, Title, Value } from './styled';

const CharacterHeader = ({ character }) => {
  return (
    <Header>
      <Box>
        <Title>
          <FormattedMessage id='character.details.name' />
        </Title>
        <Value>
          {!character.name?.parts && character.name}
          {character.name?.parts &&
            character.name.parts.map((id, index) => (
              <FormattedMessage id={id} key={index} />
            ))}
        </Value>
      </Box>
      <Box>
        <Title>
          <FormattedMessage id='character.details.class' />
        </Title>
        <Value>
          <FormattedMessage id={`character.classes.${character.class}`} />
        </Value>
      </Box>
      <Box>
        <Title>
          <FormattedMessage id='character.details.hp' />
        </Title>
        <Value>{`${character.hp}/${character.hp}`}</Value>
      </Box>
      <Box>
        <Title>
          <FormattedMessage id='character.details.omens' />
        </Title>
        <Value>
          {character.omens.rolled} (
          <FormattedMessage
            id='character.details.die'
            values={{ value: character.omens.die }}
          />
          )
        </Value>
      </Box>
    </Header>
  );
};

export default CharacterHeader;
