import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  background-image: url(${({ theme }) => theme.nechrubel});
  background-repeat: no-repeat;
  background-position: center;
  color: ${({ theme }) => theme.kill.text};
  height: 125px;
  width: 250px;
  margin-bottom: 1em;
  margin-top: 1em;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-family: calling-code, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1em;
  text-align: center;
  padding: 0;

  > span {
    background-color: ${({ theme }) => theme.kill.span};
  }

  :active {
    background-size: 100px 100px;
  }
`;
