import MB_LOGO_BLACK from '../../assets/MB_horizontal_black.svg';
import MB_LOGO_WHITE from '../../assets/MB_horizontal_white.svg';
import NECHRUBEL_YELLOW from '../../assets/Nechrubel_yellow.svg';
import NECHRUBEL_PINK from '../../assets/Nechrubel_pink.svg';
import NECHRUBEL_WHITE from '../../assets/Nechrubel_white.svg';
import NECHRUBEL_BLACK from '../../assets/Nechrubel_black.svg';
import APP_LOGO_BLACK from '../../assets/SCVM_black.svg';
import APP_LOGO_WHITE from '../../assets/SCVM_white.svg';

const BLACK = '#000000';
const WHITE = '#ffffff';
const YELLOW = '#ffe82c';
const PINK = '#f142af';
const TRANSPARENT = 'transparent';

const classic = {
  id: 'classic',
  logo: MB_LOGO_BLACK,
  scvmbirther: APP_LOGO_BLACK,
  nechrubel: NECHRUBEL_YELLOW,
  text: BLACK,
  border: BLACK,
  background: WHITE,
  heading: { background: YELLOW, text: BLACK },
  scrollbar: YELLOW,
  button: {
    background: BLACK,
    text: WHITE,
    active: { background: YELLOW, text: BLACK },
  },
  kill: {
    text: BLACK,
    span: TRANSPARENT,
  },
  equipment: { name: BLACK, description: BLACK },
};

const doom = {
  id: 'doom',
  logo: MB_LOGO_WHITE,
  scvmbirther: APP_LOGO_WHITE,
  nechrubel: NECHRUBEL_YELLOW,
  text: WHITE,
  border: WHITE,
  background: BLACK,
  heading: { background: YELLOW, text: BLACK },
  scrollbar: YELLOW,
  button: {
    background: WHITE,
    text: BLACK,
    active: { background: BLACK, text: BLACK },
  },
  kill: {
    text: WHITE,
    span: BLACK,
  },
  equipment: { name: WHITE, description: YELLOW },
};

const flesh = {
  id: 'flesh',
  logo: MB_LOGO_WHITE,
  scvmbirther: APP_LOGO_WHITE,
  nechrubel: NECHRUBEL_PINK,
  text: WHITE,
  border: WHITE,
  background: BLACK,
  heading: { background: PINK, text: BLACK },
  scrollbar: PINK,
  button: {
    background: WHITE,
    text: BLACK,
    active: { background: PINK, text: BLACK },
  },
  kill: {
    text: BLACK,
    span: WHITE,
  },
  equipment: { name: WHITE, description: PINK },
};

const pig = {
  id: 'pig',
  logo: MB_LOGO_BLACK,
  scvmbirther: APP_LOGO_BLACK,
  nechrubel: NECHRUBEL_WHITE,
  text: WHITE,
  border: WHITE,
  background: PINK,
  heading: { background: WHITE, text: BLACK },
  scrollbar: BLACK,
  button: {
    background: BLACK,
    text: WHITE,
    active: { background: WHITE, text: PINK },
  },
  kill: {
    text: BLACK,
    span: TRANSPARENT,
  },
  equipment: { name: WHITE, description: BLACK },
};

const gold = {
  id: 'gold',
  logo: MB_LOGO_BLACK,
  scvmbirther: APP_LOGO_BLACK,
  nechrubel: NECHRUBEL_BLACK,
  text: BLACK,
  border: BLACK,
  background: YELLOW,
  heading: { background: WHITE, text: BLACK },
  scrollbar: BLACK,
  button: {
    background: WHITE,
    text: BLACK,
    active: { background: BLACK, text: YELLOW },
  },
  kill: {
    text: WHITE,
    span: BLACK,
  },
  equipment: { name: BLACK, description: BLACK },
};

const ink = {
  id: 'ink',
  logo: MB_LOGO_BLACK,
  scvmbirther: APP_LOGO_BLACK,
  nechrubel: NECHRUBEL_BLACK,
  text: BLACK,
  border: BLACK,
  background: WHITE,
  heading: { background: BLACK, text: WHITE },
  scrollbar: BLACK,
  button: {
    background: BLACK,
    text: WHITE,
    active: { background: WHITE, text: BLACK },
  },
  kill: {
    text: WHITE,
    span: BLACK,
  },
  equipment: { name: BLACK, description: BLACK },
};

const sarkash = {
  id: 'sarkash',
  logo: MB_LOGO_WHITE,
  scvmbirther: APP_LOGO_WHITE,
  nechrubel: NECHRUBEL_WHITE,
  text: WHITE,
  border: WHITE,
  background: BLACK,
  heading: { background: WHITE, text: BLACK },
  scrollbar: WHITE,
  button: {
    background: WHITE,
    text: BLACK,
    active: { background: BLACK, text: WHITE },
  },
  kill: {
    text: BLACK,
    span: WHITE,
  },
  equipment: { name: WHITE, description: WHITE },
};

export const themes = [classic, doom, flesh, pig, gold, ink, sarkash];
