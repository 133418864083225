import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Wrapper } from './styled';

const CharacterButton = ({ onClick }) => {
  return (
    <Wrapper>
      <Button onClick={onClick}>
        <span>
          <FormattedMessage id='app.generate-new-character' />
        </span>
      </Button>
    </Wrapper>
  );
};

export default CharacterButton;
