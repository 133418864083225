import styled from 'styled-components';

export const Page = styled.div`
  background: #ffffff;
  color: #000000;
  font-family: 'calling-code', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  max-width: 90%;
  min-height: 90%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;

export const CharacterHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #000000;
  align-self: stretch;
  color: #000000;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Title = styled.p`
  font-weight: 700;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const Value = styled.p`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const Grid = styled.div`
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: flex;
  width: 100%;
  color: #000000;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Heading = styled.h1`
  font-family: adhesive-nr-seven, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  color: #ffffff;
  & > span {
    background-color: #000000;
    line-height: 100%;
  }
`;

export const Bold = styled.p`
  font-weight: 700;
`;

export const InnerPadding = styled.div`
  padding-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
`;

export const CharacterDescription = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: #000000;
  flex: 1;
`;

export const CharacterAbilities = styled.div`
  > ${InnerPadding} ul {
    list-style: none;
    padding-left: 0.5em;
    text-indent: 0em;
  }

  > ${InnerPadding} ul li span {
    font-weight: 700;
    float: left;
    width: 6.5em;
  }
  border-right: 1px solid;
  border-color: #000000;
  flex: 1;
`;

export const CharacterClass = styled.div`
  border-bottom: 1px solid;
  border-color: #000000;
  flex: 1;
`;

export const CharacterEquipment = styled.div`
  flex: 1;

  > ${InnerPadding} ul {
    list-style: disc;
    text-indent: 0em;
    padding-left: 1.5em;
  }
`;

export const MBLogo = styled.img`
  padding-top: 5px;
  height: 8vmin;
`;

export const AppLogo = styled.img`
  padding-bottom: 10px;
  height: 1vmin;
`;

export const LogoWrapper = styled.div`
  padding-bottom: 24px;
  text-align: center;
`;

export const FooterWrapper = styled.div`
  text-align: left;
  margin: auto;
  position: absolute;
  bottom: 0;
`;

export const Copyright = styled.p`
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const VersionInfo = styled.p`
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 8px;
  padding-left: 30px;
  padding-right: 30px;
  opacity: 0.5;
`;

export const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.button.background};
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.button.text};
  font-size: 1.25em;
  height: 40px;
  -webkit-tap-highlight-color: transparent;
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  min-width: 160px;
  max-width: 250px;
  margin: 5px;

  :active {
    background-color: ${({ theme }) => theme.button.active.background};
    color: ${({ theme }) => theme.button.active.text};
  }
`;

export {
  EquipmentName,
  EquipmentDescription,
} from '../../../Character/CharacterGrid/styled';
