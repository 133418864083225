import { fromTable } from '../common';

export const rollHabit = (table, formatMessage) => {
  const habit = fromTable(table);
  return {
    id: habit.id,
    items: habit.items || [],
  };
};

export const addHabitItem = (characterEquipment, characterHabit) => {
  if (characterHabit.items) {
    return [fromTable(characterHabit.items), ...characterEquipment].filter(
      Boolean
    );
  }
  return characterEquipment;
};
