import { fromTable } from '../common';
import sample from 'lodash/sample';

// since array.flat is an experimental feature we'll put this here in the meanwhile
if (!Array.prototype.flat) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'flat', {
    value: function (depth = 1, stack = []) {
      // eslint-disable-next-line no-unused-vars
      for (let item of this) {
        if (item instanceof Array && depth > 0) {
          item.flat(depth - 1, stack);
        } else {
          stack.push(item);
        }
      }

      return stack;
    },
  });
}

export const rollPowers = (characterClass) => {
  const powers = [
    fromTable(characterClass.powers.table, characterClass.powers.amount),
  ].flat();
  if (characterClass.specials) {
    return [...characterClass.specials.map((special) => special.variants ? {...special, id: sample(special.variants)
  } : special), ...powers];
  }
  return powers;
};

export const overrideClassParametersFromPowers = (
  characterClass,
  characterPowers
) => {
  return {
    ...characterClass,
    ...characterPowers.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {}),
  };
};
