import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  padding-left: 0.5em;
  @media only screen and (min-width: 426px) {
    padding-top: 1em;
  }
`;
