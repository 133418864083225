import styled from 'styled-components';

export const Grid = styled.div`
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: flex;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 426px) {
    flex-direction: row;
  }
`;

export const Heading = styled.h1`
  font-family: adhesive-nr-seven, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  color: ${({ theme }) => theme.heading.text};
  & > span {
    background-color: ${({ theme }) => theme.heading.background};
    line-height: 100%;
  }
`;

export const Bold = styled.p`
  font-weight: 700;
`;

export const InnerPadding = styled.div`
  padding-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
`;

export const CharacterDescription = styled.div`
  @media only screen and (max-width: 425px) {
    border-color: ${({ theme }) => theme.border};
    border-bottom: 1px solid;
  }

  @media only screen and (min-width: 426px) {
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.border};
    flex: 1;
  }
`;

export const CharacterAbilities = styled.div`
  > ${InnerPadding} ul {
    list-style: none;
    padding-left: 0.5em;
    text-indent: 0em;
  }

  > ${InnerPadding} ul li span {
    font-weight: 700;
    float: left;
    width: 6.5em;
  }

  @media only screen and (max-width: 425px) {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.border};
  }

  @media only screen and (min-width: 426px) {
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.border};
    flex: 1;
  }
`;

export const CharacterClass = styled.div`
  @media only screen and (max-width: 425px) {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.border};
  }

  @media only screen and (min-width: 426px) {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.border};

    flex: 1;
  }
`;

export const CharacterEquipment = styled.div`
  @media only screen and (max-width: 425px) {
    flex: 1;

    > ${InnerPadding} ul {
      list-style: disc;
      text-indent: 0em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  @media only screen and (min-width: 426px) {
    flex: 1;

    > ${InnerPadding} ul {
      list-style: disc;
      text-indent: 0em;
      padding-left: 1.5em;
    }
  }
`;

export const EquipmentName = styled.span`
  font-weight: 700;
  color: #000000;
`;

export const EquipmentDescription = styled.span`
  color: #000000;
`;

export const ThemedEquipmentName = styled(EquipmentName)`
  color: ${({ theme }) => theme.equipment.name};
`;

export const ThemedEquipmentDescription = styled(EquipmentDescription)`
  color: ${({ theme }) => theme.equipment.description};
`;
