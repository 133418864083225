import React, { useRef, useContext } from 'react';
import ReactToPrint from 'react-to-print';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import AppContext from '../../../../AppContext';
import MB_LOGO_BLACK from '../../../../assets/MB_horizontal_black.svg';
import APP_LOGO_BLACK from '../../../../assets/SCVM_black.svg';
import {
  CharacterHeader,
  Box,
  Value,
  Title,
  Row,
  CharacterAbilities,
  CharacterClass,
  CharacterDescription,
  CharacterEquipment,
  InnerPadding,
  Grid,
  Heading,
  Bold,
  Page,
  AppLogo,
  MBLogo,
  LogoWrapper,
  FooterWrapper,
  Copyright,
  Button,
  EquipmentDescription,
  EquipmentName,
} from './styled';

export const FormattedMessageWithoutAsterisk = (props) => {
  return (
    <FormattedMessage id={props.id} values={props.values || {}}>
      {(message) => {
        return withoutAsterisk(message);
      }}
    </FormattedMessage>
  );
};

const withoutAsterisk = (str) => {
  return str.replace('*', '').replace( /(<([^>]+)>)/ig, '');
};

class ComponentToPrint extends React.Component {
  render() {
    const character = this.props.character;

    return (
      <Page>
        <LogoWrapper>
          <AppLogo
            className='app-logo'
            src={APP_LOGO_BLACK}
            alt='SCVMBIRTHER Logo'
          />
          <br />
          <FormattedMessage id='app.description' />
          <br />
          <MBLogo src={MB_LOGO_BLACK} alt='MÖRK BORG Logo' />
        </LogoWrapper>

        <CharacterHeader>
          <Box>
            <Title>
              <FormattedMessage id='character.details.name' />
            </Title>
            <Value>
              {!character.name?.parts && character.name.replace('*', '')}
              {character.name?.parts &&
                character.name.parts.map((id, index) => (
                  <FormattedMessageWithoutAsterisk id={id} key={index} />
                ))}
            </Value>
          </Box>
          <Box>
            <Title>
              <FormattedMessage id='character.details.class' />
            </Title>
            <Value>
              <FormattedMessageWithoutAsterisk
                id={`character.classes.${character.class}`}
              />
            </Value>
          </Box>
          <Box>
            <Title>
              <FormattedMessage id='character.details.hp' />
            </Title>
            <Value>{`${character.hp}/${character.hp}`}</Value>
          </Box>
          <Box>
            <Title>
              <FormattedMessage id='character.details.omens' />
            </Title>
            <Value>
              {character.omens.rolled} (
              <FormattedMessage
                id='character.details.die'
                values={{ value: character.omens.die }}
              />
              )
            </Value>
          </Box>
        </CharacterHeader>
        <Grid>
          <Row>
            <CharacterDescription>
              <InnerPadding>
                <Heading>
                  <span>
                    <FormattedMessageWithoutAsterisk id='character.description.title' />
                    {!character.name?.parts && character.name.replace('*', '')}
                    {character.name?.parts &&
                      character.name.parts.map((id, index) => (
                        <FormattedMessageWithoutAsterisk id={id} key={index} />
                      ))}
                  </span>
                </Heading>
                {character.class === 'dead-gods-prophet' && character.origin && (
                  <>
                    <FormattedMessageWithoutAsterisk id={character.origin.id} />
                    {` `}
                    <strong>{`${character.origin.name}`}</strong>
                    {`, `}
                    <FormattedMessageWithoutAsterisk
                      id={character.origin.title}
                    />{' '}
                    <FormattedMessageWithoutAsterisk
                      id={character.origin.domain}
                    />
                    {'.'}
                    <br />
                    <br />
                    <FormattedMessageWithoutAsterisk
                      id={'character.classes.dead-gods-prophet.origin.appendix'}
                      values={{ name: character.origin.name }}
                    />
                  </>
                )}
                {character.class !== 'dead-gods-prophet' && character.origin && (
                  <>
                    <FormattedMessageWithoutAsterisk id={character.origin.id} />{character.origin.secondId && " "}
                    {character.origin.secondId && <FormattedMessageWithoutAsterisk id={character.origin.secondId} />}
                    <br />
                    <br />
                    <FormattedMessageWithoutAsterisk
                      id={`character.classes.${character.class}.origin.appendix`}
                    />
                  </>
                )}
                <p>
                  {' '}
                  <FormattedMessageWithoutAsterisk
                    id={character.traits[0]}
                  />{' '}
                  <FormattedMessage id='tables.traits.link' />{' '}
                  <span style={{ textTransform: 'lowercase' }}>
                    <FormattedMessageWithoutAsterisk id={character.traits[1]} />
                  </span>
                  {'. '}
                  <FormattedMessageWithoutAsterisk id={character.body} />{' '}
                  <FormattedMessageWithoutAsterisk id={character.habit.id} />{' '}
                  {character.tale && (
                    <FormattedMessageWithoutAsterisk id={character.tale.id} />
                  )}
                </p>
              </InnerPadding>
            </CharacterDescription>
            <CharacterClass>
              <InnerPadding>
                <Heading>
                  <span>
                    <FormattedMessageWithoutAsterisk
                      id={`character.classes.${character.class}`}
                    />
                  </span>
                </Heading>
                {character.powers.map(function (power, idx) {
                  return (
                    <div key={idx}>
                      <Bold>
                        <FormattedMessageWithoutAsterisk
                          id={`character.classes.${character.class}.power.${power.id}.title`}
                        />
                      </Bold>
                      <p>
                        <FormattedHTMLMessage
                          id={`character.classes.${character.class}.power.${power.id}.description`}
                        />
                      </p>
                    </div>
                  );
                })}
              </InnerPadding>
            </CharacterClass>
          </Row>
          <Row>
            <CharacterAbilities>
              <InnerPadding>
                <Heading>
                  <span>
                    <FormattedMessageWithoutAsterisk id='character.abilities.title' />
                  </span>
                </Heading>
                <ul>
                  <li>
                    <span>
                      <FormattedMessage id='character.abilities.strength' />
                    </span>
                    {character.abilities.strength.text}
                  </li>
                  <li>
                    <span>
                      <FormattedMessage id='character.abilities.agility' />
                    </span>
                    {character.abilities.agility.text}
                  </li>
                  <li>
                    <span>
                      <FormattedMessage id='character.abilities.presence' />
                    </span>
                    {character.abilities.presence.text}
                  </li>
                  <li>
                    <span>
                      <FormattedMessage id='character.abilities.toughness' />
                    </span>
                    {character.abilities.toughness.text}
                  </li>
                </ul>
              </InnerPadding>
            </CharacterAbilities>
            <CharacterEquipment>
              <InnerPadding>
                <Heading>
                  <span>
                    <FormattedMessage id='character.equipment.title' />
                  </span>
                </Heading>
                <ul>
                  {character.equipment.map(function (item, idx) {
                    return (
                      <li key={idx}>
                        <>
                          <EquipmentName>
                            <FormattedMessageWithoutAsterisk
                              id={item.title}
                              values={{ amount: item.amount, hp: item.hp }}
                            />
                          </EquipmentName>
                          {item.description && (
                            <EquipmentDescription>
                              <FormattedMessageWithoutAsterisk
                                id={`${item.description}`}
                                values={{ amount: item.amount, hp: item.hp }}
                              />
                            </EquipmentDescription>
                          )}
                        </>
                      </li>
                    );
                  })}
                </ul>
              </InnerPadding>
            </CharacterEquipment>
          </Row>
        </Grid>
        <FooterWrapper>
          <Copyright>
            <FormattedMessage id='app.copyright' />
          </Copyright>
          <Copyright>
            <FormattedMessage id='app.link' />
          </Copyright>
        </FooterWrapper>
      </Page>
    );
  }
}

const CharacterPrintButton = () => {
  const componentRef = useRef();
  const [state] = useContext(AppContext);
  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button>
            <FormattedMessage id='app.print' />
          </Button>
        )}
        content={() => componentRef.current}
      />

      <div style={{ display: 'none' }}>
        <ComponentToPrint character={state.character} ref={componentRef} />
      </div>
    </>
  );
};

export { CharacterPrintButton };
