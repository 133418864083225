import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Wrapper } from './styled';
import ToggleLanguageButton from '../Language/ToggleLanguageButton';
import { CharacterPrintButton } from '../Export/Print/Character';
import { isIOS } from 'react-device-detect';
import HomebrewButton from '../Homebrew/HomebrewButton';
import Modron from '../Modron';

const Menu = () => {
  const { locale } = useIntl();
  const [authorization, setAuthorization] = useState('');

  useEffect(() => {
    let params;

    params = new URLSearchParams(window.location.search);
    if (!params.has('modron_authorization')) return;

    setAuthorization(params.get('modron_authorization'));
  }, []);

  return (
    <Wrapper>
      {authorization && locale === 'en' && (
        <Modron authorization={authorization} />
      )}
      {!isIOS && <CharacterPrintButton />}
      <ToggleLanguageButton />
      <HomebrewButton />
    </Wrapper>
  );
};

export default Menu;
