import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.button.background};
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.button.text};
  font-size: 1.25em;
  height: 40px;
  -webkit-tap-highlight-color: transparent;
  font-family: calling-code, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  min-width: 160px;
  max-width: 250px;
  margin: 5px;

  :active {
    background-color: ${({ theme }) => theme.button.active.background};
    color: ${({ theme }) => theme.button.active.text};
  }
`;
