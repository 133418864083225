import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  Grid,
  Row,
  Heading,
  Bold,
  CharacterDescription,
  CharacterAbilities,
  CharacterClass,
  CharacterEquipment,
  InnerPadding,
  ThemedEquipmentName,
  ThemedEquipmentDescription,
} from './styled';

const CharacterHeader = ({ character }) => {
  return (
    <Grid>
      <Row>
        <CharacterDescription>
          <InnerPadding>
            <Heading>
              <span>
                <FormattedMessage id='character.description.title' />
                {!character.name?.parts && character.name}
                {character.name?.parts &&
                  character.name.parts.map((id, index) => (
                    <FormattedMessage id={id} key={index} />
                  ))}
              </span>
            </Heading>
            {character.class === 'dead-gods-prophet' && character.origin && (
              <>
                <FormattedMessage id={character.origin.id} />
                {` `}
                <strong>{`${character.origin.name}`}</strong>
                {`, `}
                <FormattedMessage id={character.origin.title} />{' '}
                <FormattedMessage id={character.origin.domain} />
                {'.'}
                <br />
                <br />
                <FormattedMessage
                  id={'character.classes.dead-gods-prophet.origin.appendix'}
                  values={{ name: character.origin.name }}
                />
              </>
            )}
            {character.class !== 'dead-gods-prophet' && character.origin && (
              <>
                <FormattedMessage id={character.origin.id} />{character.origin.secondId && " "}
                {character.origin.secondId && <FormattedMessage id={character.origin.secondId} />}
                <br />
                <br />
                <FormattedMessage
                  id={`character.classes.${character.class}.origin.appendix`}
                />
              </>
            )}
            <p>
              {' '}
              <FormattedMessage id={character.traits[0]} />{' '}
              <FormattedMessage id='tables.traits.link' />{' '}
              <span style={{ textTransform: 'lowercase' }}>
                <FormattedMessage id={character.traits[1]} />
              </span>
              {'. '}
              <FormattedMessage id={character.body} />{' '}
              <FormattedMessage id={character.habit.id} />{' '}
              {character.tale && <FormattedMessage id={character.tale.id} />}
            </p>
          </InnerPadding>
        </CharacterDescription>
        <CharacterClass>
          <InnerPadding>
            <Heading>
              <span>
                <FormattedMessage id={`character.classes.${character.class}`} />
              </span>
            </Heading>
            {character.powers.map(function (power, idx) {
              return (
                <div key={idx}>
                  <Bold>
                    <FormattedHTMLMessage
                      id={`character.classes.${character.class}.power.${power.id}.title`}
                    />
                  </Bold>
                  <p>
                    <FormattedHTMLMessage
                      id={`character.classes.${character.class}.power.${power.id}.description`}
                      whiteSpace='pre-line'
                    />
                  </p>
                </div>
              );
            })}
          </InnerPadding>
        </CharacterClass>
      </Row>
      <Row>
        <CharacterAbilities>
          <InnerPadding>
            <Heading>
              <span>
                <FormattedMessage id='character.abilities.title' />
              </span>
            </Heading>
            <ul>
              <li>
                <span>
                  <FormattedMessage id='character.abilities.strength' />
                </span>
                {character.abilities.strength.text}
              </li>
              <li>
                <span>
                  <FormattedMessage id='character.abilities.agility' />
                </span>
                {character.abilities.agility.text}
              </li>
              <li>
                <span>
                  <FormattedMessage id='character.abilities.presence' />
                </span>
                {character.abilities.presence.text}
              </li>
              <li>
                <span>
                  <FormattedMessage id='character.abilities.toughness' />
                </span>
                {character.abilities.toughness.text}
              </li>
            </ul>
          </InnerPadding>
        </CharacterAbilities>
        <CharacterEquipment>
          <InnerPadding>
            <Heading>
              <span>
                <FormattedMessage id='character.equipment.title' />
              </span>
            </Heading>
            <ul>
              {character.equipment.map(function (item, idx) {
                return (
                  <li key={idx}>
                    {' '}
                    <>
                      <ThemedEquipmentName>
                        <FormattedMessage
                          id={item.title}
                          values={{ amount: item.amount, hp: item.hp }}
                        />
                      </ThemedEquipmentName>
                      {item.description && (
                        <ThemedEquipmentDescription>
                          <FormattedMessage
                            id={`${item.description}`}
                            values={{ amount: item.amount, hp: item.hp }}
                          />
                        </ThemedEquipmentDescription>
                      )}
                    </>
                  </li>
                );
              })}
            </ul>
          </InnerPadding>
        </CharacterEquipment>
      </Row>
    </Grid>
  );
};

export default CharacterHeader;
