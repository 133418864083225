import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;

  @media only screen and (min-width: 1100px) {
    max-width: 65%;
  }

  @media only screen and (min-width: 1441px) {
    max-width: 40%;
  }
`;
