import { fromTable } from '../common';

export const rollTale = (table, formatMessage) => {
  const tale = fromTable(table);
  return {
    id: tale.name,
    items: tale.items || [],
  };
};

export const addTaleItem = (characterEquipment, characterTale) => {
  if (characterTale.items) {
    return [fromTable(characterTale.items), ...characterEquipment].filter(
      Boolean
    );
  }
  return characterEquipment;
};
