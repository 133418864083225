import random from 'lodash/fp/random';
import intersection from 'lodash/fp/intersection';
import { fromTable } from '../common';
import { addHabitItem } from '../Habit';
import { addTaleItem } from '../Tale';

const characterHasScroll = (characterEquipment) => {
  return characterEquipment
    .filter(Boolean)
    .filter((item) => item.tags.includes('scroll')).length;
};

export const addWeapon = (characterEquipment, characterClass, tables) => {
  if (characterClass.weapon) {
    const die = characterClass.weapon;
    const dieWithScroll = die < 'd6' ? die : 'd6';

    return [
      characterHasScroll(characterEquipment)
        ? fromTable(tables.weapons[dieWithScroll])
        : fromTable(tables.weapons[die]),
      ...characterEquipment,
    ];
  }
  return characterEquipment;
};

export const addArmor = (characterEquipment, characterClass, tables) => {
  if (characterClass.armor) {
    const die = characterClass.armor;
    const dieWithScroll = 'd2';

    return [
      characterHasScroll(characterEquipment)
        ? fromTable(tables.armor[dieWithScroll])
        : fromTable(tables.armor[die]),
      ...characterEquipment,
    ].filter(Boolean);
  }
  return characterEquipment.filter(Boolean);
};

export const withoutTags = (table, tagsToRemove) => {
  return table.filter((items) =>
    items.filter((item) => !intersection(item.tags, tagsToRemove).length)
  );
};

export const addEquipment = (characterClass, tables) => {
  if (characterClass.forbidden) {
    return [
      fromTable(withoutTags(tables.equipment_i, characterClass.forbidden)),
      fromTable(withoutTags(tables.equipment_ii, characterClass.forbidden)),
      fromTable(withoutTags(tables.equipment_iii, characterClass.forbidden)),
      fromTable(characterClass.equipment),
    ].filter(Boolean);
  }

  return [
    fromTable(tables.equipment_i),
    fromTable(tables.equipment_ii),
    fromTable(tables.equipment_iii),
    fromTable(characterClass.equipment),
  ].filter(Boolean);
};

export const addWaterAndFood = (characterEquipment, characterClass) => {
  if (characterClass.water_and_food) {
    return [characterClass.water_and_food, ...characterEquipment].filter(
      Boolean
    );
  }
  return characterEquipment;
};

export const addSilver = (characterEquipment, characterClass) => {
  return [
    ...characterEquipment,
    {
      name: 'character.details.silver',
      amount: {
        min: characterClass.silver.min,
        max: characterClass.silver.max,
      },
      tags: ['silver'],
    },
  ];
};

export const generateValuesForEquipment = (
  characterEquipment,
  characterAbilities
) => {
  return characterEquipment.map((item) => {
    if (item.amount) {
      return {
        name: item.name,
        description: item.description,
        amount:
          random(item.amount.min, item.amount.max) +
          (item.mod ? characterAbilities[item.mod].mod : 0),
        hp: item.hp ? random(item.hp.min, item.hp.max) + item.hp.mod : 0,
        tags: item.tags ?? [],
      };
    }
    return {
      name: item.name,
      description: item.description,
      amount: 1,
      hp: item.hp ? random(item.hp.min, item.hp.max) + item.hp.mod : 0,
      tags: item.tags ?? [],
    };
  });
};

export const formatEquipment = (characterEquipment, formatMessage) => {
  return characterEquipment.map((item) => {
    return {
      tags: item.tags,
      title: item.name,
      description: item.description,
      amount: item.amount,
      hp: item.hp,
    };
  });
};

export const rollEquipment = (
  characterClass,
  characterAbilities,
  characterHabit,
  characterTale,
  tables,
  formatMessage
) => {
  const basicEquipment = addEquipment(characterClass, tables);
  const habitItems = addHabitItem(basicEquipment, characterHabit);
  const taleItems = addTaleItem(habitItems, characterTale);
  const armor = addArmor(taleItems, characterClass, tables);
  const weapon = addWeapon(armor, characterClass, tables);
  const waterAndFood = addWaterAndFood(weapon, characterClass, tables);
  const silver = addSilver(waterAndFood, characterClass);
  const equipmentWithValues = generateValuesForEquipment(
    silver,
    characterAbilities
  );
  return formatEquipment(equipmentWithValues, formatMessage);
};
