import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Copyright, Disclaimer, VersionInfo } from './styled';
import AppContext from '../../AppContext';

const Footer = () => {
  const [state] = useContext(AppContext);

  return (
    <Wrapper>
      {state.homebrew && (
        <Disclaimer>
          <FormattedMessage id='app.MBC-disclaimer' />
        </Disclaimer>
      )}
      <Copyright>
        <FormattedMessage id='app.copyright' />
      </Copyright>
      <VersionInfo>
        <FormattedMessage id='app.version' />
      </VersionInfo>
    </Wrapper>
  );
};

export default Footer;
