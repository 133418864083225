import random from 'lodash/fp/random';
import { allTables } from '../../../store/tables';
import { fromTable } from './common';
import { rollPowers, overrideClassParametersFromPowers } from './Power';
import { rollAbility } from './Ability';
import { rollName } from './Name';
import { rollProsaicName } from './Name/ProsaicName';
import { rollHabit } from './Habit';
import { rollTale } from './Tale';
import { rollOrigin } from './Origin';
import { rollEquipment } from './Equipment';
import { rollHp } from './Hitpoints';

export const randomCharacter = (homebrew) => {
  const tables = homebrew ? allTables.homebrew : allTables.vanilla;

  const unmodifiedCharacterClass = fromTable(tables.classes);
  const characterPowers = rollPowers(unmodifiedCharacterClass);
  const characterClass = overrideClassParametersFromPowers(
    unmodifiedCharacterClass,
    characterPowers
  );

  const abilityModifiers = [
    { mod: -3, text: '-3', numbers: [1, 2, 3, 4] },
    { mod: -2, text: '-2', numbers: [5, 6] },
    { mod: -1, text: '-1', numbers: [7, 8] },
    { mod: 0, text: '±0', numbers: [9, 10, 11, 12] },
    { mod: 1, text: '+1', numbers: [13, 14] },
    { mod: 2, text: '+2', numbers: [15, 16] },
    { mod: 3, text: '+3', numbers: [17, 18, 19, 20] },
  ];

  const characterAbilities = {
    strength: rollAbility(
      characterClass.abilities.strength.mod,
      abilityModifiers
    ),
    agility: rollAbility(
      characterClass.abilities.agility.mod,
      abilityModifiers
    ),
    presence: rollAbility(
      characterClass.abilities.presence.mod,
      abilityModifiers
    ),
    toughness: rollAbility(
      characterClass.abilities.toughness.mod,
      abilityModifiers
    ),
  };

  const characterName = characterClass.tags.includes('prosaic-name')
    ? rollProsaicName(tables.prosaicNames)
    : rollName(tables.names);

  const characterHabit = rollHabit(tables.habits);
  const characterTale = (random(1, 10) > 8 && rollTale(tables.tales)) ?? {};
  const characterOrigin = rollOrigin(characterClass, tables);

  const character = {
    name: characterName,
    abilities: characterAbilities,
    class: characterClass.name,
    hp: rollHp(
      characterClass.hp.min,
      characterClass.hp.max,
      characterAbilities.toughness.mod
    ),
    omens: {
      rolled:
        random(characterClass.omens.min, characterClass.omens.max) +
        (characterClass.omens?.modifier ?? 0),
      die: `${characterClass.omens.max}${
        characterClass.omens?.modifier
          ? ` + ${characterClass.omens.modifier}`
          : ''
      }`,
    },
    traits: fromTable(tables.traits, 2).map((trait) => trait.id),
    body: fromTable(tables.body).id,
    habit: characterHabit,
    tale: characterTale,
    equipment: rollEquipment(
      characterClass,
      characterAbilities,
      characterHabit,
      characterTale,
      tables
    ),
    origin: characterOrigin,
    powers: characterPowers,
  };

  return character;
};
