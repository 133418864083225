import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper, MBLogo, AppLogo } from './styled';
import AppContext from '../../AppContext';

const Logo = () => {
  const [{ theme }] = useContext(AppContext);
  return (
    <Wrapper>
      <AppLogo
        className='app-logo'
        src={theme.scvmbirther}
        alt='SCVMBIRTHER Logo'
      />
      <br />
      <FormattedMessage id='app.description' />
      <br />
      <MBLogo src={theme.logo} alt='MÖRK BORG Logo' />
    </Wrapper>
  );
};

export default Logo;
