import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.border};
  align-self: stretch;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Title = styled.p`
  font-weight: 700;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const Value = styled.p`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
