import random from 'lodash/fp/random';
import { rollDeadGod } from './DeadGod';

export const rollOrigin = (characterClass, tables) => {
  const origin = `character.classes.${characterClass.name}.origin.${random(
    characterClass.origins.min,
    characterClass.origins.max
  )}.description`;


  if (characterClass.tags.includes('dead-god')) {
    return rollDeadGod(origin, tables.deadGods);
  }

  if(characterClass?.origins?.secondMin && characterClass?.origins?.secondMax) {
    const secondOrigin = `character.classes.${characterClass.name}.secondOrigin.${random(
        characterClass.origins.secondMin,
        characterClass.origins.secondMax
    )}.description`;

    return {id: origin, secondId:secondOrigin}
  }

  return {
    id: origin,
  };
};
