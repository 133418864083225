import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from './styled';
import AppContext from '../../../AppContext';

const HomebrewButton = () => {
  const [state, setState] = useContext(AppContext);

  const toggleHomebrew = () => {
    localStorage.setItem('homebrew', !state.homebrew);

    setState({
      ...state,
      homebrew: localStorage.getItem('homebrew') === 'true'
    });
  };

  return (
    <Button checked={state.homebrew} onClick={toggleHomebrew}>
      <FormattedMessage
        id={
          state.homebrew ? 'app.toggle-homebrew-off' : 'app.toggle-homebrew-on'
        }
      />
    </Button>
  );
};

export default HomebrewButton;
