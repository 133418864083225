import weapons from "./weapons";
import armor from "./armor";
import body from "./body";
import classes from "./classes";
import equipment_i from "./equipment_i";
import equipment_ii from "./equipment_ii";
import equipment_iii from "./equipment_iii";
import habits from "./habits";
import tales from "./tales";
import traits from "./traits";
import names from "./names";

import homebrew_weapons from "./homebrew/weapons";
import homebrew_names from "./homebrew/names";
import homebrew_classes from "./homebrew/classes";
import homebrew_equipment_iii from "./homebrew/equipment_iii";
import homebrew_prosaicNames from "./homebrew/prosaicNames";
import homebrew_deadGods from "./homebrew/deadGods";
import homebrew_traits from "./homebrew/traits";
import homebrew_body from "./homebrew/body";
import homebrew_habits from "./homebrew/habits";

const vanilla = {
  weapons: {
    d4: weapons.slice(0, 4),
    d6: weapons.slice(0, 6),
    d8: weapons.slice(0, 8),
    d10: weapons.slice(0, 10),
  },
  armor: {
    d2: armor.slice(0, 2),
    d3: armor.slice(0, 3),
    d4: armor.slice(0, 4),
  },
  body: body,
  classes: classes,
  equipment_i: equipment_i,
  equipment_ii: equipment_ii,
  equipment_iii: equipment_iii,
  habits: habits,
  tales: tales,
  traits: traits,
  names: names,
};

const homebrew = {
  ...vanilla,
  weapons: {
    d4: homebrew_weapons.slice(0, 4),
    d6: homebrew_weapons.slice(0, 6),
    d8: homebrew_weapons.slice(0, 8),
    d10: homebrew_weapons.slice(0, 10),
  },
  names: homebrew_names,
  prosaicNames: homebrew_prosaicNames,
  deadGods: homebrew_deadGods,
  classes: homebrew_classes,
  equipment_iii: homebrew_equipment_iii,
  traits: homebrew_traits,
  body: homebrew_body,
  habits: homebrew_habits,
};

export const allTables = { vanilla, homebrew };
