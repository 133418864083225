import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import sample from 'lodash/fp/sample';
import { IntlProvider } from 'react-intl';
import en from './translations/en.json';
import se from './translations/sv.json';
import { themes } from './features/Themes';
import { randomCharacter } from './features/Character/Random';

const initialize = () => {
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'en');
  }

  if (!localStorage.getItem('homebrew')) {
    localStorage.setItem('homebrew', false);
  }
};

initialize();

const initialState = {
  locale: localStorage.getItem('locale') || 'en',
  theme: sample(themes),
  homebrew: localStorage.getItem('homebrew') === 'true' || false,
  character: randomCharacter(
    localStorage.getItem('homebrew') === 'true' || false
  ),
};

const messages = {
  en: en,
  sv: se,
};

const AppContext = React.createContext([{}, () => {}]);

const AppContextProvider = (props) => {
  const [state, setState] = useState(initialState);

  return (
    <AppContext.Provider value={[state, setState]}>
      <IntlProvider
        locale={state.locale}
        messages={messages[state.locale]}
        defaultLocale={'en'}
      >
        <ThemeProvider theme={state.theme}>{props.children}</ThemeProvider>
      </IntlProvider>
    </AppContext.Provider>
  );
};

export { AppContextProvider };
export default AppContext;
