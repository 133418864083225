import sample from 'lodash/fp/sample';

export const rollProsaicName = (table) => {
  const parts = table.reduce((acc, curr) => {
    acc.push(sample(curr));
    return acc;
  }, []);
  return {
    parts,
  };
};

export const formatProsaicName = ({ parts, formatMessage }) => {
  return parts.map((id) => formatMessage({ id })).join('');
};
