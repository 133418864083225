import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1em;
  text-align: center;
  margin: auto;
`;

export const MBLogo = styled.img`
  padding-top: 10px;
  height: 12vmin;

  @media only screen and (max-width: 425px) {
    height: 18vmin;
  }
`;

export const AppLogo = styled.img`
  padding-bottom: 30px;
  height: 1.5vmin;

  @media only screen and (max-width: 425px) {
    height: 2.5vmin;
  }
`;
